import {
  Box,
  Text,
  Flex,
  Button,
  Grid,
  Radio,
  Group,
  Select,
  LoadingOverlay,
} from "@mantine/core";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaCalendarDays } from "react-icons/fa6";
import { LuChevronDown } from "react-icons/lu";
import { DatePickerInput } from "@mantine/dates";
import { navigateToUrl } from "single-spa";

export const AmbulanceCard = ({
  handleSubmit,
  iswithCompanion,
  utils,
  form,
  data,
  isAuthenticated,
  loadingWindow,
  classes,
  StepperComponent,
  GlobalCard,
}) => {
  if (loadingWindow) {
    return (
      <LoadingOverlay
        visible={loadingWindow}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
        data-testid="loading-overlay"
      />
    );
  }

  return isAuthenticated ? (
    <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
      <StepperComponent servicesData={data?.travelLiquidatorData} />
      <Text component="h1" color="#004236" fw={700} fz={24}>
        Transporte especial ambulancia
      </Text>
      <GlobalCard>
        <Grid>
          <Grid.Col xs={12} md={6}>
            <Radio.Group
              label="Traslado asistencial básico"
              withAsterisk
              {...form.getInputProps("basicCareTransfer")}
            >
              <Group mt={8}>
                <Radio
                  label="Si"
                  value="true"
                  className={classes.radioButton}
                />
                <Radio
                  label="No"
                  value="false"
                  className={classes.radioButton}
                />
              </Group>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Radio.Group
              label="Traslado asistencial medicalizado"
              withAsterisk
              {...form.getInputProps("medicalizedCareTransfer")}
            >
              <Group mt={8}>
                <Radio
                  label="Si"
                  value="true"
                  className={classes.radioButton}
                />
                <Radio
                  label="No"
                  value="false"
                  className={classes.radioButton}
                />
              </Group>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Radio.Group
              label="Con acompañante"
              withAsterisk
              {...form.getInputProps("withCompanion")}
            >
              <Group mt={8}>
                <Radio
                  label="Si"
                  value="true"
                  className={classes.radioButton}
                />
                <Radio
                  label="No"
                  value="false"
                  className={classes.radioButton}
                />
              </Group>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Radio.Group
              label="Disponibilidad de ambulancia"
              withAsterisk
              {...form.getInputProps("ambulanceAvailability")}
            >
              <Group mt={8}>
                <Radio
                  label="Si"
                  value="true"
                  className={classes.radioButton}
                />
                <Radio
                  label="No"
                  value="false"
                  className={classes.radioButton}
                />
              </Group>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("origin")}
              label="Origen"
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              data={data.cities}
              searchable
              icon={
                <FaMapMarkerAlt
                  size="1rem"
                  data-testid="iconMap"
                  color={form.isDirty("origin") && "#004236"}
                />
              }
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              placeholder="Ingrese el origen"
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <Select
              {...form.getInputProps("destination")}
              label="Destino"
              classNames={{
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
                item: classes.item,
              }}
              data={data.cities}
              searchable
              icon={
                <FaMapMarkerAlt
                  size="1rem"
                  color={form.isDirty("origin") && "#004236"}
                />
              }
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              placeholder="Ingrese el destino"
            />
          </Grid.Col>

          <Grid.Col xs={12} md={6}>
            <DatePickerInput
              {...form.getInputProps("beneficiaryDate")}
              type="range"
              label="Fecha (Beneficiario)"
              locale={utils.es}
              placeholder="Ingrese la fecha del beneficiario"
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
                day: classes.calendar,
                rightSection: classes.rightSection,
              }}
              valueFormat="DD/MMM/YY"
              icon={<FaCalendarDays size="1rem" color="#004236" />}
              minDate={new Date()}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <DatePickerInput
              {...form.getInputProps("companionDate")}
              locale={utils.es}
              type="range"
              label="Fecha (Acompañante)"
              placeholder="Ingrese la fecha del acompañante"
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
                day: classes.calendar,
                rightSection: classes.rightSection,
              }}
              disabled={!iswithCompanion}
              valueFormat="DD/MMM/YY"
              icon={<FaCalendarDays size="1rem" color="#004236" />}
              minDate={new Date()}
              withAsterisk
            />
          </Grid.Col>
        </Grid>
      </GlobalCard>
      <Flex gap={16}>
        <Button
          className={classes.buttonOutline}
          fullWidth
          onClick={() => navigateToUrl("/auxilios")}
        >
          Cancelar
        </Button>
        <Button className={classes.button} fullWidth onClick={handleSubmit}>
          Continuar
        </Button>
      </Flex>
    </Box>
  ) : null;
};
