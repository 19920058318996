import { useEffect, useState } from "react";
import {
  useStyles,
  GlobalCard,
  StepperComponent,
} from "@mfe/js-common-ave-uiutils";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import { useForm } from "@mantine/form";

import {
  fetchLocalCitiesData,
  getAccessTokenApi,
  fetchTraveLiquidatorByIDData,
  fetchTraveLiquidatorUpdateData,
  moment,
  es,
} from "@mfe/ts-common-ave-utilitaries";
import { AmbulanceCard } from "../components/ambulanceCard.component";

export default function BookAmbulanceService({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <BookAmbulanceServiceComponent organization={organization} />
    </MsalProvider>
  );
}

export const BookAmbulanceServiceComponent = ({ organization }) => {
  const { classes } = useStyles();

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState("");
  const [loadingWindow, setLoadingWindow] = useState(false);
  const [data, setData] = useState({
    cities: [],
    travelLiquidatorData: {
      ViajeTrayecto: [],
      TipoViajeAlias: "",
    },
  });

  const form = useForm({
    initialValues: {
      basicCareTransfer: "false",
      medicalizedCareTransfer: "false",
      withCompanion: "false",
      ambulanceAvailability: "false",
      origin: "",
      destination: "",
      beneficiaryDate: [],
      companionDate: [],
    },
  });

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchTraveLiquidatorByIDData(
        setData,
        tokenApi,
        localStorage.getItem("liquidationID"),
        setLoadingWindow
      );
      fetchLocalCitiesData(setData, tokenApi);
    }
  }, [tokenApi]);

  const ambulanceData = data?.travelLiquidatorData?.ViajeTrayecto[0];
  const iswithCompanion = ambulanceData?.auxilioMedico?.Acompanante;

  useEffect(() => {
    if (ambulanceData) {
      form.setValues({
        withCompanion: String(ambulanceData?.auxilioMedico?.Acompanante),
        beneficiaryDate: [
          moment(
            ambulanceData?.auxilioMedico?.PerBeneficiario?.fechaInicioViaje
          ).toDate(),
          moment(
            ambulanceData?.auxilioMedico?.PerBeneficiario?.fechaFinViaje
          ).toDate(),
        ],
        companionDate: iswithCompanion
          ? [
              moment(
                ambulanceData?.auxilioMedico?.PerAcompanante?.fechaInicioViaje
              ).toDate(),
              moment(
                ambulanceData?.auxilioMedico?.PerAcompanante?.fechaFinViaje
              ).toDate(),
            ]
          : [null, null],
        origin: [
          ambulanceData?.CodigoIataorigen,
          ambulanceData?.ciudadOrigenGrupo,
          ambulanceData?.nombreCiudadOrigen,
        ].join("/"),
        destination: [
          ambulanceData?.CodigoIatadestino,
          ambulanceData?.ciudadDestinoGrupo,
          ambulanceData?.nombreCiudadDestino,
        ].join("/"),
      });
    }
  }, [ambulanceData]);

  const handleSubmit = () => {
    fetchTraveLiquidatorUpdateData(
      tokenApi,
      {
        ...data?.travelLiquidatorData,
        ViajeTrayecto: [
          {
            ...ambulanceData,
            ServicioBpo: ambulanceData?.ServicioBpo?.map((item, index) => ({
              ...item,
              descripcionServicio: String(JSON.stringify(form.values)),
            })),
          },
        ],
      },
      setLoadingWindow
    );
  };

  const props = {
    handleSubmit,
    iswithCompanion,
    utils: { es },
    form,
    data,
    isAuthenticated,
    loadingWindow,
    classes,
    StepperComponent,
    GlobalCard,
  };

  return <AmbulanceCard {...props} />;
};
